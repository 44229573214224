'use strict';

var dialog = require('../../dialog'),
    minicart = require('../../minicart'),
    page = require('../../page'),
    util = require('../../util'),
    Promise = require('promise'),
    ajax = require('../../ajax'),
    progress = require('../../progress'),
    initializeEvents = require('./initializeEvents'),
    _ = require('lodash');
   const val_quantity = ".value-quantity";

    $('body').click(function(e) {
        if(!e.className === 'add-to-cart-tile') {
           minicart.close(1000);
       }
    });
/**
 * @description Make the AJAX request to add an item to cart
 * @param {Element} form The form element that contains the item quantity and ID data
 * @returns {Promise}
 */
var addItemToCart = function (form) {
    var $form = $(form),
    $qty = $form.find('input[name="Quantity"]');
    if ($qty.length === 0 || isNaN($qty.val()) || parseInt($qty.val(), 10) === 0) {
        $qty.val('1');
    }
    
	  if ($('.qty-acc').length > 0){
		  $qty.val('1');
	  }
    return Promise.resolve($.ajax({
        type: 'POST',
        url: util.ajaxUrl(Urls.addProduct),
        data: $form.serialize()
    })).then(function (response) {
        // handle error in the response
        if(response.quantityAllowedExceeded) {
            $(".pdpForm").find('.hideonlarge-error').removeClass('d-none');
            $(".pdpForm").find(".product-quantity").addClass('maxQ_show');

            $(".pdpForm").find("#addQtocart").css("pointer-events", "none");
        } else if (response && response.errorPerso){
            const $autoComplete = $(".pdpForm").find("#autocomplete");
            const $btnPersonalize = $('.btn-personalize');
            const $qtePerso = $('.qte-perso');

            $btnPersonalize.prop('disabled', true);
            $btnPersonalize.addClass('invalidPersonnalisation');
            var currentQuantityInBasket = $qtePerso.find('.quantityInBasket').html();
            if(currentQuantityInBasket < 1){
                $btnPersonalize.show();
                $('.plus-moins').hide();
                $qtePerso.hide();
            }
            $autoComplete.prop('disabled', false);
            if (response.invalidPostalCode){
                $(".pdpForm").find('#invalidPostalCode').removeClass('d-none');
            }
            if (response.invalidLocation){
                $(".pdpForm").find('#invalidLocation').removeClass('d-none');
            }
            $autoComplete.prop('readonly', false);
            if($autoComplete.hasClass("readonly-input")){
                $autoComplete.removeClass("readonly-input");
            }
        } else {
            if($('.btn-personalize').length){
                const $autoComplete = $(".pdpForm").find("#autocomplete");
                const $btnPersonalize = $('.btn-personalize');
                const $qtePerso = $('.qte-perso');

                $btnPersonalize.prop('disabled', true);
                var currentQuantity = $qtePerso.find('.value-quantity').html();
                var currentQuantityInBasket = $qtePerso.find('.quantityInBasket').html();
                $qtePerso.find('.value-quantity').html(parseInt(currentQuantity) + 1);
                $qtePerso.find('.quantityInBasket').html(parseInt(currentQuantityInBasket) + 1);
                $btnPersonalize.hide();
                $('.plus-moins').show();
                $qtePerso.show();
                $autoComplete.prop('readonly', true);
                $autoComplete.addClass("readonly-input");
            }

            return response;
        }
    });
};
/**
 * 
 */
var addItemToCartWithQty = function (form) {
    var $form = $(form),
    $qty = $form.find('input[name="Quantity"]');
    $qty.val($('.qty-acc').val());
    if ($qty.length === 0 || isNaN($qty.val()) || parseInt($qty.val(), 10) === 0) {
        $qty.val('1');
    }
    return Promise.resolve($.ajax({
        type: 'POST',
        url: util.ajaxUrl(Urls.addProduct),
        data: $form.serialize()
    })).then(function (response) {
        // handle error in the response
        if (response == '') {
            throw new Error(response.error);
        } else {
            return response;
        }
    });
};

/**
 * @function
 * @description performs a generic ajax cart submit
 * */

var ajaxrq;
var currentproductuuid;
function postAjaxCartFormInsideCart(action, pid) {
    var rowPID = $(".cart-table").find(".rowPID[value='" + pid + "']");
    var uuid = rowPID.closest('li.cart-row').data('uuid');
    var cartRow = $(".cart-table").find(".cart-row[data-uuid='" + uuid + "']");
    cartRow.find(".product-price").html('<div class="loading"></div>');
    cartRow.find(".price-option").hide();
    progress.show($('.product-rows'));
	var $form = $('[name="dwfrm_cart"]');
    var data = ajax.appendParameterToAjaxRequestData($form.serialize(), action);
    ajaxrq = $.ajax({
        url : $form.attr('action'),
        type: "POST",
        data: data,
        success: function(result){
            $.ajax({
                type: 'POST',
                url: util.ajaxUrl(Urls.cartShow),
                data: {
                    updatecarttype : 'ajax',
                    productuuid : uuid
                },
                success: function(response){
                    var uuid_price = $(response).find(".mini-cart-product-"+uuid).find('.mini-cart-price').html();
                    var price_unadjusted = $(response).find(".mini-cart-product-"+uuid).find('.price-unadjusted').html();
                    var option_price_adjusted = $(response).find(".mini-cart-product-"+uuid).find('.additonalOption').find('.mini-cart-price').html();
                    var order_total = $(response).find('.mini-cart-subtotals').find('.value').html();
                    var total_qty = $(response).find('.minicart-quantity').html();
                    var qty_product = $(response).find(".mini-cart-product-"+uuid).find('.mini-cart-pricing.qte .qty_prdct').html();
                    var qtyRow = cartRow.find('.value-quantity').text();
                    var engraving_price = cartRow.find('input[name="optionPrice"]').val();
                    if(engraving_price) {
                        if(option_price_adjusted) {
                            engraving_price = parseFloat(engraving_price.split(' ')[1]);
                            var total_engraving_price = (engraving_price*parseInt(qtyRow)).toFixed(2);
                            total_engraving_price = total_engraving_price.replace('.', ',');
                            if (parseFloat(total_engraving_price.replace(',', '.')) !== parseFloat(option_price_adjusted.replace(',', '.'))) {
                                var lang = $('html').attr('lang');
                                if (lang === 'en') {
                                    cartRow.find(".price-option").show();
                                    cartRow.find('.price-option .value').html('<strike>' + Resources.CURRENCY_SYMBOL + total_engraving_price + '</strike>' + '<span class="text-accent">+'+ option_price_adjusted);
                                } else {
                                    cartRow.find(".price-option").show();
                                    cartRow.find('.price-option .value').html('<strike>' + total_engraving_price + ' ' + Resources.CURRENCY_SYMBOL + '</strike>' + '<span class="text-accent">+'+ option_price_adjusted);
                                }
                            } else {
                                var lang = $('html').attr('lang');
                                if (lang === 'en') {
                                    cartRow.find(".price-option").show();
                                    cartRow.find('.price-option .value').html('+ ' + Resources.CURRENCY_SYMBOL + total_engraving_price);
                                } else {
                                    cartRow.find(".price-option").show();
                                    cartRow.find('.price-option .value').html('+ ' + total_engraving_price + ' ' + Resources.CURRENCY_SYMBOL);
                                }
                            }
                            
                        } else {
                            engraving_price = parseFloat(engraving_price.split(' ')[1]);
                            var total_engraving_price = (engraving_price*parseInt(qtyRow)).toFixed(2);
                            total_engraving_price = total_engraving_price.replace('.', ',');
                            var lang = $('html').attr('lang');
                            if (lang === 'en') {
                                cartRow.find(".price-option").show();
                                cartRow.find('.price-option .value').html('+ ' + Resources.CURRENCY_SYMBOL + total_engraving_price);
                            } else {
                                cartRow.find(".price-option").show();
                                cartRow.find('.price-option .value').html('+ ' + total_engraving_price + ' ' + Resources.CURRENCY_SYMBOL);
                            }
                        }
                    }
                    var price_old = $(response).find(".mini-cart-product-"+uuid).find('.price-unadjusted').find('.strikethrough ').html();
                    if(price_unadjusted) {
                        if (parseFloat(price_old.replace(',', '.')) !== parseFloat(uuid_price.replace(',', '.'))) {
                            cartRow.find('.product-price .loading').replaceWith('<span class="text-accent">' + uuid_price + '</span>' + price_unadjusted);
                        }
                        else {
                            cartRow.find('.product-price .loading').replaceWith(uuid_price);
                        }
                    } else {
                        cartRow.find('.product-price .loading').replaceWith(uuid_price);
                    }
                    cartRow.find('.value-quantity').html(qty_product);
                    cartRow.find('#quanity').val(qty_product);
                    $(".order-value").html(order_total);
                    $('.first-step .left-bloc-top-bar').html(Resources.CARTSTEPTITLE + ' (' + total_qty + ')' );
                    $('.packing-bloc').load(location.href+" .packing-bloc>*");
                    $('.product-rows').load(location.href+" .product-rows>*", function() {
                        progress.hide();
                        initializeEvents.init();
                    });
                    $('#cart-messages').load(location.href+" #cart-messages>*");
                }
            });
        }
    });
}

/**
 * @description Handler to handle the add to cart event
 */
var addToCart = function (e) {
    e.preventDefault();
    var $form = $(this).closest('form');
    var encreaseqte = '.quantityInBasket, .value-quantity';
    const quantityInBasket_ =  ".quantityInBasket";
    if($form.find('.gravure-required').text() === "true") {
        $form.find('.product-grave-option1').addClass('graveur-added');
        var quantity = $('span.value-quantity').text();
        var messageAGraver = $('.messageAGraver').text();
        var checkboxMessageLabel = $('#product-grave-content-message-option > fieldset > div > label');
        var textMessageLabel = checkboxMessageLabel.not('.sansgravure').contents().filter(function() {
            return this
        }).toArray();
        if(parseInt(quantity) === 0){
            $('.quantity').addClass('hide');
            $(".inventory").css("display","none");
            $("#add-to-cart").css("display","block");
        }
        else if(parseInt(quantity) !== 0 & textMessageLabel[1].data !== messageAGraver){
            $('.quantity').addClass('hide');
            $("#add-to-cart").css("display","block");
        }
        return;
    }
    else{
        if($('.quantity').hasClass('hide')){
            $('.quantity').removeClass('hide');
        }
        //Push Datalayer to GTM for event ADD TO CART in case of Product-Detail Page
        if(typeof dataLayer !== 'undefined') {
            var id =$('input[name*="_productid"]').val();
            var name = $('#product-name-hidden').val();
            var brand = $('#product-brand-hidden').val();
            var price = $('.price-sale').attr('data-itemprice');
            var category = $('#path-cat-hidden').val();
            var variant = $('input[name="defaultVariant"]').val();
            var quantity = $('#Quantity').val();
            var rowPID = $(".cart-table").find(".rowPID[value='" + id + "']");
            var puuid = rowPID.closest('li.cart-row').data('uuid');
            dataLayer.push({
                "event": "addToCart",
                "ecommerce": {
                    "add": {
                    "products": [{
                    "name": name,
                    "id": id,
                    "price": price,
                    "brand": brand,
                    "category": category,
                    "variant": variant,
                    "quantity": quantity
                    }]
                    }
                }
            });
        }
        addItemToCart($form).then(function (response) {
            var $uuid = $form.find('input[name="uuid"]');
            if ($uuid.length > 0 && $uuid.val().length > 0) {
                page.refresh();
            } else {
                var maxQty = parseInt($('.maximumOrderQuantity').text());
                var maxStock = parseInt($('#maximumOrderQuantity').data('available'));
                var newval;
                if($('#pdpMain').length && !$('.btn-personalize').length) {
                    newval = parseInt($(quantityInBasket_).html()) + parseInt($('#Quantity').val());
                } else if ($('.btn-personalize').length){
                    newval = parseInt($(quantityInBasket_).html())
                } else {
                    newval = parseInt($(quantityInBasket_).html()) + 1;
                }
                if((maxQty === 0 && newval > maxStock)) {
                    return;
                }
               else if ((maxQty != 0) && (!(maxQty > maxStock && newval <= maxStock) && !(maxQty < maxStock && newval <= maxQty))) {
                return;
                }
                else {
                    $(this).closest('.product-add-to-cart').find(encreaseqte).html(newval);
                    if(!$(this).hasClass('btn-personalize') || !$(this).hasClass('invalidPersonnalisation')){
                        $('#add-to-cart').css("display", "none");
                    }
                    minicart.show(response);
                    $(this).closest('.product-add-to-cart').find(encreaseqte).html(newval);                }
            }
        }.bind(this));
        
    }
};

var addToCartFromTile = function(e)
{
    e.preventDefault();
    var $form = $(this).closest('form');
    var encreaseqte = '.quantityInBasket, .value-quantity';
    // for product set on recette page
    if($form.hasClass('product-set-qte')){
        $form.find('.img-tile-click').switchClass("show","hide");
        $form.find('.addcart_plp').switchClass("hide","show");
    }
    if($form.find('.plp-gravure-required').text() === "true") {
        $form.parents('.product-tile').find('.img-tile-click').switchClass( "hide", "show");
        $form.parents('.product-tile').find('.img-tile-click').stop();
        $form.parents('.product-tile').find('.addcart_plp').switchClass("show","hide");
        $form.parents('.product-tile').find('.addcart_plp').stop();
        $form.parents('.product-tile').find('.error-gravure-required').removeClass('hide');
        $('#error-gravure-popup').css('display', 'block');
        $('#error-gravure').css('display', 'block');
        $('#error-gravure-popup').show();
        $('.popup-header i').on('click' , function () {
            $('#error-gravure-popup').hide();
            $('#error-gravure').css('display', 'none');
        }); 
    }else{
        //Push Datalayer to GTM for event ADD TO CART in case of Product-Tile
        if(typeof dataLayer !== 'undefined'){
            var id = $form.find('input[name="DLProductID"]').val();
            var name = $form.find('input[name="DLProductName"]').val();
            var brand = $form.find('input[name="DLProductBrand"]').val();
            var price = parseFloat($form.find('input[name="DLProductPrice"]').val());
            var category = $form.find('input[name="DLProductCategory"]').val();
            var variant = $form.find('input[name="DLProductVariant"]').val();
            var currencyCode = $form.find('input[name="DLCurrencyCode"]').val();
            var quantity = 1;
            var rowPID = $(".cart-table").find(".rowPID[value='" + id + "']");
            var puuid = rowPID.closest('li.cart-row').data('uuid');
            
            
            dataLayer.push({
                "event": "addToCart",
                "ecommerce": {
                    "currencyCode" : currencyCode,
                    "add": {
                    "products": [{
                    "name": name,
                    "id": id,
                    "price": price,
                    "brand": brand,
                    "category": category,
                    "variant": variant,
                    "quantity": quantity
                    }]
                    }
                }
                });
        }

        var maxOrderQuantity = $form.find('input[name="maxOrderQuantity"]').val();
        var quantityInbasket = $form.find('input[name="quantityInbasket"]').val();
        if($('.pt_cart').length) {
            var minicart_item = $('#mini-cart').find('.productID').filter(function(){return this.value === id });
            if(minicart_item) {
                quantityInbasket = minicart_item.parent().find('.qty_prdct').text();
            }
            if(!quantityInbasket) {
                quantityInbasket = 0;
            }
            minicart.init();
            if(ajaxrq && currentproductuuid && currentproductuuid === puuid ){
                ajaxrq.abort();
            }else{
                currentproductuuid = puuid;
            }
            postAjaxCartFormInsideCart('dwfrm_cart_updateCart', id)
        }
        var threshold = parseInt(maxOrderQuantity) - parseInt(quantityInbasket);

        if (parseInt(maxOrderQuantity) != 0) {
            if (parseInt(threshold) == 0) {

                $('body').addClass('removeScroll');
                $('.listProducts').fadeIn();

                $('.listProducts i').on('click', function () {
                    $('.listProducts').fadeOut();
                    $('body').removeClass('removeScroll');
                });
                return;
            }

        }
        addItemToCart($form).then(function (response) {
            $form.find('input[name="quantityInbasket"]').val(parseInt(quantityInbasket) + parseInt(1))
            var $uuid = $form.find('input[name="uuid"]');
            if ($uuid.length > 0 && $uuid.val().length > 0) {
                page.refresh();
            } else {
                // do not close quickview if adding individual item that is part of product set
                // @TODO should notify the user some other way that the add action has completed successfully
                if (!$(this).hasClass('sub-product-item')) {
                    dialog.close();
                }
                minicart.show(response);
                $(this).closest('.taxAvailable').find(encreaseqte).html(parseInt(quantityInbasket) + parseInt(1));
            } 
        }.bind(this));
    }
} 



/**
 * @description Handler to handle the add all items to cart event
 */
var addAllToCart = function (e) {
    e.preventDefault();
    var $productForms = $('#product-set-list').find('form').toArray();
    Promise.all(_.map($productForms, addItemToCart))
        .then(function (responses) {
            dialog.close();
            // show the final response only, which would include all the other items
            minicart.show(responses[responses.length - 1]);
            // Refrech Product set qte for recette details
            var $formProductSetQte = $(".product-set-list").find("form.producttile-form.icon-panier.product-set-qte");
            if($formProductSetQte.length) {
                $formProductSetQte.closest(".product-set-list").load(location.href + " .product-set-list");
            }
        });
};
/**
 * @description Handler to handle the add all items with the qty to cart  event
 */
var addAllToCartWithQty = function (e) {
    e.preventDefault();
    var $productForms = $('#product-set-list').find('form').toArray();
    Promise.all(_.map($productForms, addItemToCartWithQty))
        .then(function (responses) {
            dialog.close();
            // show the final response only, which would include all the other items
            minicart.show(responses[responses.length - 1]);
        });
};

var deleteItemToCart_ = function (form) {
    var $form = $(form);
    var $qty = 1;
    return Promise.resolve($.ajax({
        type: 'POST',
        url: util.ajaxUrl(Urls.deleteProductfromCartItems), 
        data: $form.serialize()
    })).then(function (response) {
        // handle error in the response
        if (response === '') {
            throw new Error(response.error);
        } else {
            $(".pdpForm").find('.hideonlarge-error').addClass('d-none');
            $(".pdpForm").find("#addQtocart").css("pointer-events", "auto");

            return response; 
        }
    });
};

var removeItemfromcart = function (e) { 

	 e.preventDefault();
    var $form = $(this).closest('form');
    var pdp_val = $(val_quantity).eq(0);
    var v = pdp_val.html();
    const quantityInBasket = '.quantityInBasket';

    var id = $('input[name*="_productid"]').val();
    var name = $('h1.product-name').text();
    var brand = $('h1.product-name > .brand').text();
    var price = $('.price-sale').attr('data-itemprice');
    var category = $(".breadcrumb a:last").text();
    var variant = $('input[name="defaultVariant"]').val();
    var quantity = $('#Quantity').val();
    var rowPID = $(".cart-table").find(".rowPID[value='" + id + "']");
    var puuid = rowPID.closest('li.cart-row').data('uuid');

        //Push Datalayer to GTM for event remove from cart
        if (typeof dataLayer !== 'undefined') {
            dataLayer.push({
                "event": "removeFromCart",
                "ecommerce": {
                    "remove": {
                        "products": [{
                            "name": name,
                            "id": id,
                            "price": price,
                            "brand": brand,
                            "category": category,
                            "variant": variant,
                            "quantity": quantity
                        }]
                    }
                }
            });
        }

    if($('#pdpMain').length) {
        var qtyV = $('#Quantity').val();
        if(parseInt(v) <= parseInt(qtyV) ){
            $('.inventory').hide();
            $(".displayqte").show();
        }
    }else if(parseInt(v) === parseInt(1)) {
        $('.inventory').hide();
        $(".displayqte").show();
    }
    deleteItemToCart_($form).then(function (response) {
            // do not close quickview if adding individual item that is part of product set
            // @TODO should notify the user some other way that the add action has completed successfully
            if (!$(this).hasClass('sub-product-item')) {
                dialog.close();
            }
            $('.product-add-to-cart .error').removeClass('show');
            $('.product-add-to-cart .error').addClass('hide');
            minicart.show(response);
            
            $('.product-add-to-cart .error').hide();
            var newQtyInBasket = 0;
            if($('#pdpMain').length) {
                var qtyV = $('#Quantity').val();
                if(parseInt(qtyV)<v){
                    newQtyInBasket = v - parseInt(qtyV);
                }
            }else{
                newQtyInBasket = v - 1;
            }

            $(quantityInBasket).html(newQtyInBasket);
            pdp_val.html(newQtyInBasket);

    }.bind(this));
}

var tileRemoveItemfromcart = function (e) 
{
    e.preventDefault();
    var $form = $(this).closest('form');
    var v = $(this).parent().find(val_quantity).text();
    var quantityInBasket_r = '.quantityInBasket';
    var quantityInbasket_input = $form.find('input[name="quantityInbasket"]').val();
    var id = $('input[name*="_productid"]').val();
    var name = $('h1.product-name').text();
    var brand = $('h1.product-name > .brand').text();
    var price = $('.price-sale').attr('data-itemprice');
    var category = $(".breadcrumb a:last").text();
    var variant = $('input[name="defaultVariant"]').val();
    var quantity = $('#Quantity').val();
    var rowPID = $(".cart-table").find(".rowPID[value='" + id + "']");
    var puuid = rowPID.closest('li.cart-row').data('uuid');

    //Push Datalayer to GTM for event remove from cart
    if (typeof dataLayer !== 'undefined') {
        dataLayer.push({
            "event": "removeFromCart",
            "ecommerce": {
                "remove": {
                    "products": [{
                        "name": name,
                        "id": id,
                        "price": price,
                        "brand": brand,
                        "category": category,
                        "variant": variant,
                        "quantity": quantity
                    }]
                }
            }
        });
    }
    if($('.pt_cart').length) {
        var minicart_item = $('#mini-cart').find('.productID').filter(function(){return this.value === id });
        if(minicart_item) {
            quantityInbasket_input = minicart_item.parent().find('.qty_prdct').text();
        }
        minicart.init();
        if(ajaxrq && currentproductuuid && currentproductuuid === puuid ){
            ajaxrq.abort();
        }else{
            currentproductuuid = puuid;
        }
        postAjaxCartFormInsideCart('dwfrm_cart_decreaseProductInCart', id)
    }
    if (parseInt(v) === parseInt(1)) {
        $(this).parent().switchClass( "show", "hide").hide();
        $(this).parent().parent().find('span.img-tile-click').switchClass( "hide", "show" ).show();
    }
    deleteItemToCart_($form).then(function (response) {
        $form.find('input[name="quantityInbasket"]').val(parseInt(quantityInbasket_input) - parseInt(1));
        // do not close quickview if adding individual item that is part of product set
        // @TODO should notify the user some other way that the add action has completed successfully
        if (!$(this).hasClass('sub-product-item')) {
            dialog.close();
        }
        minicart.show(response);
        var qtevalinb = $(this).parent().find('.quantityInBasket').text();
        if (parseInt(v) === parseInt(1)) {
            $(this).parent().find(val_quantity).html(0);
            $(this).parent().find(quantityInBasket_r).html(0);
        }
        else{
            $(this).parent().find(val_quantity).html(parseInt(qtevalinb) - parseInt(1));
            $(this).parent().find(quantityInBasket_r).html(parseInt(qtevalinb) - parseInt(1));
        }
    }.bind(this));
}
/**
 * @function
 * @description Binds the click event to a given target for the add-to-cart handling
 */


if ($('.product-tile').length && !$('.pt_product-details').length > 0) {
    $('.add-to-cart[disabled]').attr('title', $('.availability-msg').text());
    $(document).on('click', '.add-to-cart', addToCart);
    $(document).on('click', '.add-to-cart-2', addToCart);
    $(document).on('click', '.add-to-cart-tile-img', addToCartFromTile);
    $('#add-all-to-cart').on('click', addAllToCart);
    $('#add-product-to-cart').on('click', addAllToCartWithQty);
    //return;
    $(document).on('click', '.removeP', tileRemoveItemfromcart);
    $('.error-gravure-popup').css('display', 'none');
    $('.error-gravure').css('display', 'none');
}

/**
 * Lot switch : activat Lot unit option
 */
function activeOptionLotUnit(){
    var lotPurchaseUnit = $('.lotPurchaseUnit');
    var lotPurchaseSets = $('.lotPurchaseSets');
    lotPurchaseSets.removeClass('active');
    lotPurchaseUnit.addClass('active');
    $('#Quantity').val(1);
    $('.product-price.price_mobile').find('.mobile_price').show();
    $('.product-price.price_mobile').find('.product-weight_mobile').show();
    $('.product-price.price_mobile').find('.lotPurchaseSetsMob').hide();
}

/**
 * Lot switch : activat Lot Set option
 */
function activeOptionLotSet(){
    var lotPurchaseUnit = $('.lotPurchaseUnit');
    var lotPurchaseSets = $('.lotPurchaseSets');
    lotPurchaseUnit.removeClass('active');
    lotPurchaseSets.addClass('active');
    $('#Quantity').val(lotPurchaseSets.data('unit'));
    $('.product-price.price_mobile').find('.mobile_price').hide();
    $('.product-price.price_mobile').find('.product-weight_mobile').hide();
    $('.product-price.price_mobile').find('.lotPurchaseSetsMob').show();
}

module.exports = function () {

    $('.add-to-cart[disabled]').attr('title', $('.availability-msg').text());
    $(document).on('click', '.add-to-cart', addToCart);
    $(document).on('click', '.add-to-cart-2', addToCart);
    $(document).on('click', '.add-to-cart-tile-img', addToCartFromTile);
    $('#add-all-to-cart').on('click', addAllToCart);
    $('#add-product-to-cart').on('click', addAllToCartWithQty);
    $(document).on('click', '.removeQ1', removeItemfromcart);
    $(document).on('click', '.removeP', tileRemoveItemfromcart);   

    // Product Lot switches
    $(document).on('click','.lotPurchaseUnit', activeOptionLotUnit);
    $(document).on('click','.lotPurchaseSets', activeOptionLotSet);

};
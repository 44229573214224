'use strict';

var account = require('./account'),
    bonusProductsView = require('../bonus-products-view'),
    quickview = require('../quickview'),
    util = require('../util'),
    Promise = require('promise'),
    productTile = require('../pages/product/addToCart'),
    cartStoreInventory = require('../storeinventory/cart');

/**
 * @private
 * @function
 * @description Binds events to the cart page (edit item's details, bonus item's actions, coupon code entry)
 */

var agePopUpHandler = function () {
    if ($('.popupAgeContainer').length && $('.reservation-container').length === 0) {
        $('body , html').addClass('removeOverflow');
        $('body').on('click', '.popupAgeContainer .popup-content a ', function () {
            $('.popup-age , .overlay-popup').fadeOut();
            $('body , html').removeClass('removeOverflow');
        });
    }
    
}

function initializeEvents() {

    function removeEmojies(ele){
        var str = '';
        if(ele.length != 0)
        {
            
            var specialChars = /[?;:§!@#~^°µ<>{}\[\]|\\\/\*\$%\^\&*\)\(+=_]+/; 
            var ranges = ['[\u1000-\uFFFF]'];
            str = $(ele).val();
            str = str.replace(new RegExp(ranges.join('|'), 'ig'), '');
            str = str.replace(specialChars, '');
            $(ele).val(str);            
        }
        return str;
    }

    function minOrderPopinHandler(){
        var data;
        if ($(".gravure-text-panier").length) {
            $.each($(".gravure-text-panier"), function (index, value) {
                if($.trim($(this).val()) === "" && $(this).parent().hasClass('with-grave')) {
                    $('.cartcheckoutbutton').prop("disabled", true);
                    $(this).addClass('error-empty-gravure');
                    $('html,body').animate({
                        scrollTop:  $(document).find('.error-empty-gravure').offset().top -500
                    }, 'slow');
                    data = false;
                } else {
                    $(this).removeClass('error-empty-gravure');
                }
            });
        }
        if(data != false) {
            var order_Min_PopIn = $('#orderMinPopIn');
            var order_total_value_class = $('.orderTotalMinmum');
            var order_total_value = $("#orderTotal").val();
            $.ajax({
                url : util.ajaxUrl(Urls.getTotalPriceFromCart),
                type: "GET",
                async : false,
                success: function (response) {
                    $("#orderTotal").val(response.data);
                    var order_total_value = parseFloat($("#orderTotal").val());
                    if(order_total_value < SitePreferences.MIN_ORDER_TOTAL && order_Min_PopIn.hasClass('hide') ){
                        unCheckedPackaging();
                        if(!$('#error-packaging-popup').hasClass('show')){
                        // Set the hidden input with the order total
                        order_Min_PopIn.removeClass('hide');
                        $('#error-minOrder-popupp , #error-minOrder').fadeIn();
                        $('#error-minOrder-popup').show();
                        $('body').on('click' , '.popup-header i, .overlay-popup', function () {
                                $('.product-price').show(); 
                                $('#error-minOrder-popupp, #error-minOrder').fadeOut();
                                $('#error-packaging-popup').hide().removeClass('show');
                                order_Min_PopIn.addClass('hide');
                            });   
                        }
                    } else {
                       submitCartCheckoutForm();
                    }
                },
            });
        }
        setTimeout(function () {
            $('.cartcheckoutbutton').prop("disabled", false);
        }, 500);
    }

    function giftMessageHandler(){
        var giftmsg = removeEmojies($('textarea#dwfrm_shippingbilling_singleshipping_shippingAddress_giftMessage'));
        var newgiftmsg = giftmsg.replace(/\s/g, "");
        if (newgiftmsg !== undefined && newgiftmsg !== null && newgiftmsg !== '') {
            $.ajax({
                url: util.ajaxUrl(Urls.setgiftmessage),
                type: 'POST',
                data: {giftmsg: giftmsg},
                dataType: 'json'
            }).done(function (data) {
                
            });
        }
    }

    function giftMessgaeAdd(msg){
        if(msg !== undefined && msg !== null && msg !==''){
            $.ajax({
                url: util.ajaxUrl(Urls.setgiftmessage),
                type: 'POST',
                data: {giftmsg: msg},
                dataType: 'json'
            }).done(function (data) {
                
            });
        }
    }

    function giftMessageRemove(){
        $.ajax({
            url: util.ajaxUrl(Urls.setgiftmessage),
            type: 'POST',
            data : {giftmsg: null},
            dataTye: 'json'
        }).done(function(data){
        })
    }
   

    $('#cart-table').on('click', '.item-edit-details a', function (e) {
        e.preventDefault();
        quickview.show({
            url: e.target.href,
            source: 'cart'
        });
    })
        .on('click', '.bonus-item-actions a, .item-details .bonusproducts a', function (e) {
            e.preventDefault();
            bonusProductsView.show(this.href);
        });

    // override enter key for coupon code entry
    $('form input[name$="_couponCode"]').on('keydown', function (e) {
        if (e.which === 13 && $(this).val().length === 0) {
            return false;
        }
    });

    //to prevent multiple submissions of the form when removing a product from the cart
    var removeItemEvent = false;
    $('body').on('click', 'button[name$="deleteProduct"]', function (e) {
        if (removeItemEvent) {
            e.preventDefault();
        } else {
            removeItemEvent = true;
        }
    });

    $('.gravureForm').on('click', '.labeloption', function (e) {
        var $form = $(this).closest('form'),
            $qty = $form.find('input[name="Quantity"]');
        if ($qty.length === 0 || isNaN($qty.val()) || parseInt($qty.val(), 10) === 0) {
            $qty.val('1');
        }
        return Promise.resolve($.ajax({
            type: 'POST',
            url: (Urls.updateCart),
            data: $form.serialize()
        })).then(function (response) {
            // handle error in the response
            if (response.error) {
                throw new Error(response.error);
            } else {
                //console.log('after update');
            }
        });


    });
// Initial setup
let $gravureTextPanierElements = $(".gravure-text-panier");
$gravureTextPanierElements.each(function() {
    let $gravureTextPanier = $(this); // Reference to the current element
    let textValuegravur = $gravureTextPanier.val();
    let newlineCount = (textValuegravur.match(/\n/g) || []).length;
    let isSingleLine = true; // Initialize with the assumption that the text is single line
    let maxLengthInitial = parseInt($gravureTextPanier.attr('maxlength'), 10);

    if (newlineCount > 0) {
        $gravureTextPanier.attr('maxlength', maxLengthInitial + newlineCount);
    }

    $gravureTextPanier.keyup(function(e) {
        var textValue = $(this).val();
        var currentLength = textValue.length;
        var maxLength = parseInt($(this).attr('maxlength'));
        var $placeholder = $(this).attr('placeholder');

        // Determine whether the text is on one line or multiple lines
        var containsLineBreak = textValue.includes('\n');
        var newIsSingleLine = !containsLineBreak;

        // Check if the state has changed
        if (isSingleLine !== newIsSingleLine) {
            isSingleLine = newIsSingleLine; // Update the state

            // Handle state change: single line to multiple lines or vice versa
            if (isSingleLine) {
                // Reset maxlength to initial value when going back to one line
                $(this).attr('maxlength', maxLengthInitial);
            } else {
                if (currentLength < maxLength) {
                    $(this).attr('maxlength', maxLength + 1);
                }
            }
        }

        // Convert text to uppercase or lowercase based on data attribute
        if ($(this).data('gravuretextetype') === 'Majuscule') {
            $(this).val($(this).val().toUpperCase());
        } else if ($(this).data('gravuretextetype') === 'Minuscule') {
            $(this).val($(this).val().toLowerCase());
        }

        $(this).attr('placeholder', $placeholder);
    });

    $gravureTextPanier.keyup(function() {
        if ($(this).data('gravuretextetype') === 'Majuscule') {
            $(this).val($(this).val().toUpperCase());
        } else if ($(this).data('gravuretextetype') === 'Minuscule') {
            $(this).val($(this).val().toLowerCase());
        }
    });
});

    
    $(document).on('click', '.cartcheckoutbutton', function () {
        $.each($(".gravure-text-panier"), function (index, value) {

            if($.trim($(this).val()) === "" && $(this).parent().hasClass('with-grave')) {
                $('.cartcheckoutbutton').prop("disabled", true);
                $(this).addClass('error-empty-gravure');
                $('html,body').animate({
                    scrollTop:  $(document).find('.error-empty-gravure').offset().top -500
                }, 'slow');
            } else {
                $(this).removeClass('error-empty-gravure');
            }

        });
        setTimeout(function () {
            $('.cartcheckoutbutton').prop("disabled", false);
        }, 500);
    });

    function engravingRegexCart() {
        $(document).on('keypress', '.gravure-text-panier', function (event) {
            var regex = new RegExp("^[a-zA-Z0-9!@é'àç?èù#$&\\s+()-`.+,/\"]*$");
            var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        });
        return true;
    }
    engravingRegexCart();

    $('.box-pack').each(function(){
        var boxpack = $(this);
        if(boxpack.prop("checked") === true){
            $('#boite-uncheck').removeClass("hide");
            $('.packing-explain').css('margin-left', '0px');
        }
    });
        var cartRow = $('.packaging').find('.cart-row');
            $('body').on("click.handlerClick", '.add-pack', function(){
                var addPack = $(this),
                    selected = null;
                var $boxPackId = addPack.siblings('.product-boxpack').val();
    
                $('.remove-pack').each(function() { // finds activated giftboxes
                    if (!$(this).hasClass('hide')) {
                        $(this).trigger('click');
                    } 
                });  

                $.ajax({
                    url: util.ajaxUrl(Urls.addOrRemoveGiftBox),
                    type: 'POST',
                    data: {boxPackId: $boxPackId, action: 'addBox'},
                    success: function (response) {
                        $('.cart-order-totals .order-totals-table').replaceWith(response);
                    }
                });
                addPack.addClass("clicked");
                addPack.next().removeClass("hide");
                var boxItem = addPack.data('box');
                $('#'+boxItem).fadeIn();
                $('.add-pack').show().css("display", "inline-block");
                addPack.parents('.cart-row').find('.add-pack').hide();
                addPack.parents('.cart-row').find('.remove-pack').show();
                $('input.box-pack').prop('checked', false);
                $('.count-items').val('0');
                $('.max-items-msg').addClass('hide');
            });
      
       

        $('body').on("click.handlerClick", '.remove-pack',function(){        
            var removePack = $(this);
            var $boxPackId = removePack.siblings('.product-boxpack').val();
            $.ajax({
                url: util.ajaxUrl(Urls.addOrRemoveGiftBox),
                type: 'POST',
                data: {boxPackId: $boxPackId, action: 'removeBox'},
                success: function (response) {
                    $('.cart-order-totals .order-totals-table').replaceWith(response);
                }
            });
            if(removePack.prev().hasClass("hide"))
            {
                removePack.prev().removeClass("hide");
            }
            removePack.prev().removeClass("clicked");
            removePack.addClass("hide");
            cleanBox();
            var boxItem = removePack.data('box');
            $('#'+boxItem).fadeOut();
            $('.add-pack').show().css("display", "inline-block");
            removePack.parents('.cart-row').find('.add-pack').show();
            removePack.parents('.cart-row').find('.remove-pack').hide();
            $('input.box-pack').prop('checked', false);
            $('.count-items').val('0');
            $('.max-items-msg').addClass('hide');            
        });

        $('body').on("click.handlerClick", '.box-pack',function(){ 
            var boxPack = $(this);
            var box = boxPack.parent().parent().data('boxpack');
            var productID = $('#'+box).val();
            var checkedItems = [];
            $('.box-pack').each(function(){
                var boxpack = $(this);
                if(boxpack.prop("checked") === true){
                    checkedItems.push(boxpack.data('pdctid'));
                }
            });
            var checkMaxGiftItems = null;
            $.ajax({
                url: util.ajaxUrl(Urls.checkMaxProductsInGiftBox),
                type: 'POST',
                data: {checkedItems: JSON.stringify(checkedItems), boxID: productID},
                success: function (response) {
                    checkMaxGiftItems = response;
                }
            }).done(function () {
                if(checkMaxGiftItems === ""){
                    boxPack.prop('checked', false);
                    $('.max-items-msg').removeClass('hide');
                }else{
                    $('.max-items-msg').addClass('hide');
                }
            });
        });
        
         // code to reset button status on load

        if (cartRow.find(".count-items").val()>0 && cartRow.find('.box-items').attr('style') !== undefined)
        {   
            var buttonId = cartRow.find(".add-pack").attr('id');
            $("#"+buttonId).addClass('hide');
            $("#"+buttonId).next().removeClass('hide');
        }
        
    
    
    $('textarea#dwfrm_shippingbilling_singleshipping_shippingAddress_giftMessage').attr({
        maxlength : 500
    });
    $(document).on('click', '#message-check', function(){
        if($('textarea#dwfrm_shippingbilling_singleshipping_shippingAddress_giftMessage').val()) {
            $(this).addClass('clicked');
            $(this).parents('.message-cadeau-add').find('#message-uncheck').removeClass('hide');
            $('.message-add').show().css("display", "inline-block");
            $(this).parents('.message-cadeau-add').find('.message-add').hide();
            $(this).parents('.message-cadeau-add').find('.message-remove').show();
            var giftMsg = removeEmojies($('textarea#dwfrm_shippingbilling_singleshipping_shippingAddress_giftMessage'));
            giftMessgaeAdd($('textarea#dwfrm_shippingbilling_singleshipping_shippingAddress_giftMessage').val());
        }
    });
    $(document).on('click', '#message-uncheck', function(){
        $('textarea#dwfrm_shippingbilling_singleshipping_shippingAddress_giftMessage').attr({maxlength : 500});
        $('textarea#dwfrm_shippingbilling_singleshipping_shippingAddress_giftMessage').attr('placeholder', Resources.GIFTMESSAGE_BOX_PLACEHOLDER);
        $(this).parents('.message-cadeau-add').find('#message-check').removeClass('hide');
        $(this).parents('.message-cadeau-add').find('#message-check').removeClass('clicked');
        $(this).add('hide');
        $('.message-add').show().css("display", "inline-block");
        $(this).parents('.message-cadeau-add').find('.message-add').show();
        $(this).parents('.message-cadeau-add').find('.message-remove').hide();
        $('textarea#dwfrm_shippingbilling_singleshipping_shippingAddress_giftMessage').val('');
        giftMessageRemove();
    });

   $('body').on("click", '.add-pack',function(){
        var boxinput = $(this).parents('.cart-row').find('.item-quantity input[name=pack]');
        boxinput.trigger('click');

    });
    
    // removes gift box from cart
    function cleanBox()
    { 
        $.ajax({
            url: Urls.resetQtyPacked,
            type: 'POST',
            data: {},
            success: function (response) {
                $('.cart-order-totals .order-totals-table').replaceWith(response);
            },
            
        });
    }       

    $('body').on("click", 'div.product-grave-classique input[type=checkbox]',function(){
        var productID = $(this).closest('div.product-grave').find('input[name="pid"]').val();
        var uuid = $(this).closest('div.product-grave').find('input[name="uuid"]').val();
        var Quantity = $(this).closest('div.product-grave').find('input[name="Quantity"]').val();
        var disableGravure;
        if (!$(this).is(':checked')) {
        	disableGravure = "y";
        	$(this).closest('div.product-grave').find("textarea[name=customizedText]").val();
        }
        else {
        	disableGravure = "n";
        }
        $.ajax({
            url: util.ajaxUrl(Urls.addGravureToPorduct),
            type: 'POST',
            data: {uuid: uuid, disableGravure: disableGravure, pid: productID, Quantity: Quantity},
            success: function (response) {
                $('.cart-order-totals .order-totals-table').replaceWith(response);
                var total = parseFloat($('.order-value')[0].textContent.replace(/\s\€/gm, '')).toFixed(2);
                var order_total_value_class = $('.orderTotalMinmum');
                $.ajax({
                    url: util.ajaxUrl(Urls.getGravurePrice),
                    type: 'POST',
                    data: {uuid: uuid, disableGravure: disableGravure, pid: productID, Quantity: Quantity},
                    success: function (result) {
                    	$("li[data-uuid='" + uuid +"'] div.price-option").remove();
                    	$("li[data-uuid='" + uuid +"'] div.product-price").after( result );
                        if(total >= SitePreferences.MIN_ORDER_TOTAL){
                            order_total_value_class.addClass('hide');
                        } else {
                            order_total_value_class.removeClass('hide');
                        }
                    }
                });
            }
        });
    });

    $("textarea[name=customizedText]").each(function( index ) {
        var gravureTxt = $(this).val();
        if(gravureTxt.indexOf(" - ") >= 0){
            $(this).prop('disabled', true);
            $(this).parent().parent().find('input[type=checkbox]').prop('disabled', true);
        }
    });

    $('body').on("focusout", 'textarea[name=customizedText]',function(){
    	var uuid = $(this).closest('div.product-grave').find('input[name="uuid"]').val();
        var customizedText = $(this).val();
      $.ajax({
          url: util.ajaxUrl(Urls.addGravureTextToPorduct),
          type: 'POST',
          data: {uuid: uuid, customizedText: customizedText},
          success: function (response) {
          }
      });
    });
    var ranges = ['[\u1000-\uFFFF]' ]; //Contains all emojis
    $('body').on('keyup', 'textarea[name=customizedText]', function (event) {
        //remove smily
        var str = $(this).val();
        str = str.replace(new RegExp(ranges.join('|'), 'ig'), '');
        //remove special Characters
        str = str.replace(/[§@#~^°µ<>{}[\]|\$%\^\&(]+/, '');
        $(this).val(str);
    });
    $('body').on("click", '.bag-pack',function(){
        var productID = $(this).val();
        $.ajax({
            url: util.ajaxUrl(Urls.createBagPackLineItem),
            type: 'POST',
            data: {productID: productID},
            success: function (response) {
                $('.cart-order-totals .order-totals-table').replaceWith(response);
            }
        });
    });
    $('body').on("click", '.no-pack',function(){
        $.ajax({
            url: util.ajaxUrl(Urls.updateCartByNoPackingChoice),
            type: 'POST',
            data: {},
            success: function (response) {
                $('.cart-order-totals .order-totals-table').replaceWith(response);
            }
        });
    });

     $('body').on("click", '.input-textarea, .textarea',function(){
        $(this).attr("placeholder", "");
    });

    $('body').on("keyup", 'textarea#dwfrm_shippingbilling_singleshipping_shippingAddress_giftMessage',function(e){
        removeEmojies($('textarea#dwfrm_shippingbilling_singleshipping_shippingAddress_giftMessage'));
        var enteredText = $(this).val();
        var numberOfLineBreaks = (enteredText.match(/\n/g)||[]).length;
        if (numberOfLineBreaks >= 5) {
            length = enteredText.length;
            $(this).attr({
                maxlength : length
            });
        }else{
            $(this).attr({
                maxlength : 500
            });
        }
    });

    $('body').on("change", 'textarea#dwfrm_shippingbilling_singleshipping_shippingAddress_giftMessage',function(event){
        var giftMsg = removeEmojies($('textarea#dwfrm_shippingbilling_singleshipping_shippingAddress_giftMessage'));
        $.ajax({
                url: util.ajaxUrl(Urls.setgiftmessage),
                type: 'POST',
                data: {giftmsg: giftMsg},
                dataType: 'json'
        }).done(function(data){
        });
    });

    $('#error_gift').hide();
    
    // Submit the checkout-form
    function submitCartCheckoutForm(){
        $(".hidden-cart-action").find(':submit').trigger('click');
    }

    $('body').on("click", '[id^=cartcheckoutbutton]',function(e){
        e.preventDefault();
        managePackaging(e).then(function(){
            giftMessageHandler();
            minOrderPopinHandler();
            var items = document.getElementsByClassName('product-price');
            unCheckedPackaging();
        });
    });
}

function managePackaging(e) {
    var selectedItems = [];
    
    // Check selected boxes
    $('.box-pack').each(function() {
        var boxpack = $(this);
        if (boxpack.prop("checked") === true) {
            selectedItems.push(boxpack.data('pdctid'));
        }
    });
    
    if (selectedItems.length > 0) {
        var itemArray = [];
        var countMap = {};
        
        // Count quantity of each item
        $.each(selectedItems, function(index, value) {
            if (countMap.hasOwnProperty(value)) {
                countMap[value] += 1;
            } else {
                countMap[value] = 1;
            }
        });

        $.each(countMap, function(value, quantity) {
            itemArray.push({ productID: value, quantity: quantity });
        });

        itemArray = JSON.stringify(itemArray);
        return new Promise(function(resolve, reject) {
            $.ajax({
                url: util.ajaxUrl(Urls.updateQtyToBePacked),
                type: 'POST',
                data: { itemArray: itemArray },
                success: function(response) {
                    resolve(response);
                },
                error: function() {
                    reject('AJAX request failed');
                }
            });
        });
    } else {
        // If no items selected, return a resolved Promise immediately
        return Promise.resolve();
    }
}

// Submit the Check for unchecked Packaging
    function unCheckedPackaging(){
        var state = $('.add-pack').hasClass('clicked');
        if (state) {
            var productTobePacked = document.querySelectorAll('input[name="productpack"]');
            productTobePacked = Array.from(productTobePacked);
            var productCheck = false;
            productTobePacked.forEach(function(item) {
                if(item.checked){
                    productCheck = true;
                }
            });
            if (productCheck !== true) {
                $('#error-packaging-popup , #error-packaging').fadeIn();
                $('#error-packaging-popup').show().addClass('show');
                $('body').on('click' , '.popup-header i, .overlay-popup', function () {
                    $('#error-packaging-popup , #error-packaging').fadeOut();
                    $('#error-packaging-popup').hide().removeClass('show');
                    var productPricesDiv = document.querySelectorAll('.product-price');
                    Array.from(productPricesDiv).forEach(function(item){
                        item.style.display = 'block';
                    });
                });
                window.stop();
                e.preventDefault();
                e.stopPropagation();
                return false;
            }
        }  
    }

var cart = {
    init : function () {
        initializeEvents();
        agePopUpHandler();
        if (SitePreferences.STORE_PICKUP) {
            cartStoreInventory.init();
        }
        if($('.isAlcoolInCart').length && !$('.checkoutlogin.newregistration').length ){
            account.initCartLogin();
        }
        $(document).on('click', '.add-to-cart', () => {productTile.addToCart});
        $(document).on('click', '.add-to-cart-2', () => {productTile.addToCart});
        $(document).on('click', '.add-to-cart-tile-img', () => {productTile.addToCart});
    },
    calculateTotalTillShippingPromo: function (ajaxOrderTotal){
        var promoLimit = $('.progress').data('limit');
        if(promoLimit && promoLimit != 'none' && promoLimit != 'unlimited'){
            var orderTotalString = ajaxOrderTotal.replace(',','.');
            var orderTotal = parseFloat(orderTotalString).toFixed(2);
            if (orderTotal < promoLimit){
                var progressAmountLeft = (promoLimit-orderTotal).toFixed(2);
                var progressAmountPercentage = (orderTotal/promoLimit)*100;
                $('#promo-progress').html(progressAmountLeft + Resources.CURRENCY_SYMBOL);
                $('.progress-bar').attr('style','max-width:' + progressAmountPercentage + '%;width: 100%;')
                if(!$('#fullProgressBar').hasClass('hide')){
                    $('#fullProgressBar').addClass('hide');
                }
                if($('#thresholdProgressBar').hasClass('hide')){
                    $('#thresholdProgressBar').removeClass('hide');
                }
                if($('.progress').hasClass('hide')){
                    $('.progress').removeClass('hide');
                }
            } else {
                $('.progress-bar').attr('style','max-width:100%;width: 100%;');
                if(!$('#thresholdProgressBar').hasClass('hide')){
                    $('#thresholdProgressBar').addClass('hide');
                }
                if($('#fullProgressBar').hasClass('hide')){
                    $('#fullProgressBar').removeClass('hide');
                }
                if($('.progress').hasClass('hide')){
                    $('.progress').removeClass('hide');
                }
            }
        } else if (promoLimit === 'none'){
            if($('#fullProgressBar').hasClass('hide')){
                $('#fullProgressBar').removeClass('hide');
            }
            if(!$('.progress').hasClass('hide')){
                $('.progress').addClass('hide');
            }
        } else if (promoLimit === 'unlimited'){
            if($('#noLimitProgressBar').hasClass('hide')){
                $('#noLimitProgressBar').removeClass('hide');
            }
            if(!$('.progress').hasClass('hide')){
                $('.progress').addClass('hide');
            }
        }
    },
    loadProgressBar: function (){
        var orderTotal = $('.order-value').html();
        this.calculateTotalTillShippingPromo(orderTotal);
    }

}

module.exports = cart;
